<template>
  <div class="contract-modal">
    <div id="component">
      <div class="filter-panel">
        <input
            type="text"
            placeholder="搜索企业/个人名称"
            style="margin-left: 0;"
            v-model="keyword"
            class="cs-input"
        />
        <CSSelect>
          <select v-model="waterNun">
            <option value="">水表剩余预存金额不限</option>
            <option :value="1">小于50元</option>
            <option :value="2">小于150元</option>
            <option :value="3">小于350元</option>
            <option :value="4">小于700元</option>
            <option :value="5">大于等于700</option>
          </select>
        </CSSelect>
        <CSSelect>
          <select v-model="electricityNum">
            <option value="">电表剩余预存金额不限</option>
            <option :value="1">小于50元</option>
            <option :value="2">小于150元</option>
            <option :value="3">小于350元</option>
            <option :value="4">小于700元</option>
            <option :value="5">大于等于700</option>
          </select>
        </CSSelect>
        <button
            type="button"
            class="btn btn-primary btn-sm"
            @click="queryCompanyOwnerMethod"
        >
          查询
        </button>
      </div>

      <div class="result-panel">
        <CSTable :thead-top="filterHeight">
          <template v-slot:header>
            <div class="table-header-panel text-right">
              <button
                  type="button"
                  class="btn btn-primary sticky-right btn-sm"
                  @click="openEditOwnerModel(null)"
              >
                <svg
                    class="icon"
                    aria-hidden="true"
                >
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                添加租客
              </button>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <!--<th>租客ID</th>-->
              <th class="text-center">企业/个人名称</th>
              <th class="text-center">法人/身份</th>
              <th class="text-center">统一社会信用代码/身份证号</th>
              <th class="text-center">对公/对私账号</th>
              <th class="text-center">水表剩余预存金额（元）</th>
              <th class="text-center">电表剩余预存金额（元）</th>
              <th class="text-center">对接人</th>
              <th class="text-center">性别</th>
              <!-- <th class="text-center">年龄</th> -->
              <th class="text-center">手机</th>
              <!--                            <th class="text-center">邮箱</th>-->
              <th class="text-center">操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="tenant in tenantList" :key="tenant.id">
              <td class="text-center">
                                <span
                                    class="allow-click"
                                    @click="_openOwnerDetailModel(tenant)">
                                    {{ tenant.companyName }}
                                </span>
              </td>
              <td class="text-center">
                {{ tenant.legalEntity }}
              </td>
              <td class="text-center">
                {{ tenant.ucsi || '-' }}
              </td>
              <td class="text-center">
                {{ tenant.businessAccount || '-' }}
              </td>
              <td v-if="tenant.waterBalance==null">
                -
              </td>
              <td v-else>
                <span class="btn-active"
                      @click="seeWaterBtn(tenant)">{{
                    tenant.waterBalance < 0 ? '欠' + tenant.waterBalance * -1 : tenant.waterBalance
                  }}</span>
              </td>
              <td v-if="tenant.electricityBalance==null">
                -
              </td>
              <td v-else>
                <span class="btn-active"
                      @click="seeElectricityBtn(tenant)">{{
                    tenant.electricityBalance < 0 ? '欠' + tenant.electricityBalance * -1 : tenant.electricityBalance
                  }}</span>
              </td>
              <td class="text-center">
                {{ tenant.deputyName }}
              </td>
              <td class="text-center">
                {{ tenant.deputyGender || '-' }}
              </td>
              <!-- <td>{{tenant.age || '-'}}</td> -->
              <td class="text-center">
                {{ tenant.deputyPhone || '-' }}
              </td>
              <!--                            <td class="text-center">-->
              <!--                                {{ tenant.deputyEmail || '-' }}-->
              <!--                            </td>-->
              <td class="text-center">
                <div class="btn-group">
                  <button
                      type="button"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                  >
                    操作
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                          @click="openEditOwnerModel(tenant)"
                          style="width: 100%; text-decoration: none;"
                      >
                        修改租客
                      </a
                      >
                    </li>
                    <li>
                      <a
                          @click="openDelOwnerModel(tenant)"
                          style="width: 100%; text-decoration: none;"
                      >
                        删除租客
                      </a
                      >
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </CSTable>
        <Pagination/>
      </div>

      <EditCompanyTenant
          name="EditCompanyTenant"
          componentName="EditCompanyTenant"
          componentTitle="租客"
          ownerTypeCd="1003"
          notifyLoadDataComponentName="listCompanyOwner"
      ></EditCompanyTenant>

      <DeleteOwner
          name="deleteOwner"
          componentName="DeleteOwner"
          notifyLoadDataComponentName="listOwner"
      ></DeleteOwner>

      <SearchRoom
          name="searchRoom"
          componentName="SearchRoom"
          emitChooseRoom="listOwner"
          emitLoadData="listOwner"
          roomFlag="1"
          showSearchCondition="false"
      ></SearchRoom>

      <SearchParkingSpace
          name="searchParkingSpace"
          componentName="SearchParkingSpace"
          emitChooseParkingSpace="listOwner"
          emitLoadData="listOwner"
          parkingSpaceFlag="SH"
          showSearchCondition="false"
      ></SearchParkingSpace>

      <CSDialog :dialog-visible="tenantDetailModal.visible" dialog-width="820px" :dialog-show-confirm-btn="false"
                dialog-header-class="alert-bg"
                class="tenant-detail-dialog" @onClose="tenantDetailModal.visible = false" dialog-title="查看租客信息"
                dialog-cancel-btn-text="关闭"
      >
        <template v-slot:dialog-content>
          <div style="max-height: 550px; overflow-y: auto; font-size: 24px; padding: 30px;">
            <div class="preview-tenant">
              <p class="field">
                <span class="field-label">企业/个人名称</span>
                <span class="field-content">{{
                    tenantDetailModal.tenantInfo.companyName || '-'
                  }}</span>
              </p>
              <p class="field">
                <span class="field-label"> 法人/身份 </span>
                <span class="field-content">{{
                    tenantDetailModal.tenantInfo.legalEntity || '-'
                  }}</span>
              </p>
              <p class="field">
                <span class="field-label" style="line-height: 1;">营业执照/身份证</span>
                <template
                    v-if="
                                    tenantDetailModal.tenantInfo.businessLicense
                                                                         "
                >
                  <img
                      style="max-width: 200px"
                      :src="
                                        tenantDetailModal.tenantInfo
                                            .businessLicense
                                    "
                  />
                </template>
                <template v-else>-</template>
              </p>
              <p class="field">
                            <span class="field-label">统一社会信用代码/身份证号</span
                            ><span class="field-content">{{
                  tenantDetailModal.tenantInfo.ucsi || '-'
                }}</span>
              </p>
              <p class="field">
                            <span class="field-label">对公/对私账号</span
                            ><span class="field-content">{{
                  tenantDetailModal.tenantInfo.businessAccount ||
                  '-'
                }}</span>
              </p>
              <p class="field">
                <span class="field-label">对接人</span>
                <span class="field-content">
                                {{
                    tenantDetailModal.tenantInfo.deputyName ||
                    '-'
                  }}
                            </span>
              </p>
              <p class="field">
                <span class="field-label">性别</span>
                <span class="field-content">
                                {{ tenantDetailModal.tenantInfo.deputyGender }}
                            </span>
              </p>
              <!--                            <p class="field">
                                          <span class="field-label">身份证</span
                                          ><span class="field-content">
                                              {{
                                                  tenantDetailModal.tenantInfo.deputyIdNo ||
                                                  '-'
                                              }}
                                          </span>
                                          </p>-->
              <p class="field">
                            <span class="field-label">手机</span
                            ><span class="field-content">
                                {{
                  tenantDetailModal.tenantInfo.deputyPhone ||
                  '-'
                }}
                            </span>
              </p>
              <!--                            <p class="field">
                                          <span class="field-label">邮箱</span
                                          ><span class="field-content">
                                              {{
                                                  tenantDetailModal.tenantInfo.deputyEmail ||
                                                  '-'
                                              }}
                                          </span>
                                          </p>-->
              <p class="field">
                            <span class="field-label">备注</span
                            ><span class="field-content">
                                {{
                  tenantDetailModal.tenantInfo.comment || '-'
                }}
                            </span>
              </p>
              <template
                  v-if="
                            tenantDetailModal.rooms &&
                            tenantDetailModal.rooms.length > 0
                        "
              >
                <div class="field">
                  <div class="field-label"
                       style="display:inline-block;line-height: 1;vertical-align: top;">承租房间
                  </div>
                  <div class="field-content"
                       style="display:inline-block;line-height: 1;vertical-align: top;">
                    <ul class="list-group">
                      <li v-for="(room, index) in tenantDetailModal.rooms" :key="index">
                        {{ room.buildingName }}-{{ room.roomName }}
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
            </div>

          </div>
        </template>
      </CSDialog>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
import EditCompanyTenant from "@/components/EditCompanyTenants.vue";
import DeleteOwner from "@/components/DeleteOwner.vue";
import SearchRoom from "@/components/SearchRoom.vue";
import SearchParkingSpace from "@/components/SearchParkingSpace.vue";
import {queryTenantUrl, queryRenterBindRoomsUrl, queryLesseeBindRoomsUrl} from "@/requestUrl";
import CSDialog from "@/components/common/CSDialog";
import CSTable from "@/components/common/CSTable";
import CSSelect from "@/components/common/CSSelect"


export default {
  props: {},
  created() {
    window.addEventListener("keydown", this.queryCompanyOwnerMethodDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.queryCompanyOwnerMethodDown);
  },
  deactivated() {
    window.removeEventListener("keydown", this.queryCompanyOwnerMethodDown);
  },
  components: {
    CSTable,
    EditCompanyTenant,
    DeleteOwner,
    SearchRoom,
    SearchParkingSpace,
    Pagination,
    CSDialog,
    CSSelect
  },
  data() {
    return {
      keyword: "",
      filterHeight: 0,
      tenantList: [],
      tenantDetailModal: {
        visible: false,
        rooms: [],
        tenantInfo: {},
      },
      waterNun: "",
      electricityNum: "",
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
      /* "companyListOwnerInfo": {
              "companyMoreCondition": false,
              "companyConditions": {
                  "name":"",
                  "idCard":"",
                  "link":"",
                  "ownerId":"",
                  "isEnterprise":1,
                  "ownerTypeCd":"1003",
                  "floorId":"",
                  "floorName":"","unitId":"","roomNum":"","roomId":"","enterpriseName":""
              },
              "companyOwners":[]
          } */
    };
  },

  mounted() {
    this.queryCompanyOwnerMethod();
    this.$vc.on(this.$route.path, "pagination", "page_event", (_currentPage) => {
      this.pageParams.pageNo = _currentPage;
      this.companyListOwner(_currentPage);
    });
    this.$vc.on(this.$route.path, "listCompanyOwner", "listCompanyOwnerData", () => {
      this.companyListOwner();
    });
    this.$vc.on(this.$route.path, "listOwner", "listOwnerData", () => {
      this.companyListOwner();
    });

    this.$vc.on(this.$route.path, "companyOwner", "notify", (_param) => {
      if (_param.hasOwnProperty("floorId")) {
        this.companyListOwnerInfo.companyConditions.floorId =
            _param.floorId;
      }

      if (_param.hasOwnProperty("unitId")) {
        this.companyListOwnerInfo.companyConditions.unitId =
            _param.unitId;
      }

      if (_param.hasOwnProperty("roomId")) {
        this.companyListOwnerInfo.companyConditions.roomId =
            _param.roomId;
        this.companyListOwner();
      }
    });
    this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    window.addEventListener('resize', () => {
      this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    })
  },
  updated() {
    this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
  },
  methods: {

    queryCompanyOwnerMethodDown(e) {
      if (e.keyCode == 13) {
        this.queryCompanyOwnerMethod();
      }
    },

    /**
     * 查询租客绑定的房间
     * @param {Number} lesseeId 租客ID
     *
     *  */
    queryRenterBindRooms(lesseeId) {
      return new Promise((resolve) => {
        this.$fly
            .post(queryRenterBindRoomsUrl, {
              lesseeId,
              regionId: this.$vc.getCurrentRegion().communityId,
            })
            .then((res) => {
              resolve(res);
            });
      });
    },
    companyListOwner(pageNo = this.pageParams.pageNo, pageSize = 10) {
      const params = {
        regionId: this.$vc.getCurrentRegion().communityId,
        companyName: this.keyword,
        keyword: "",
        pageNo,
        pageSize,
        type: 2,
        waterNun: this.waterNun,
        electricityNum: this.electricityNum
      };
      if (params.companyName === "") {
        delete params.companyName;
      }
      this.$fly.post(queryTenantUrl, params).then((res) => {
        this.tenantList = res.data.datas;
        /* this.companyListOwnerInfo.total = listOwnerData.total;
            this.companyListOwnerInfo.records = listOwnerData.records; */
        if (pageNo === 1) {
          this.$vc.emit(this.$route.path, 'pagination', 'init', {
            total: res.data.total || 0,
            currentPage: pageNo,
            pageSize,
          });
        }

      });
    },
    changeMoreCondition() {
      this.companyListOwnerInfo.companyMoreCondition = !this
          .companyListOwnerInfo.companyMoreCondition;
    },
    openEditOwnerModel(owner) {
      console.log(owner);
      this.$vc.emit(this.$route.path, "EditCompanyTenant", "show", owner);
    },
    openDelOwnerModel(owner) {
      this.$vc.emit(this.$route.path, "deleteTenant", "openOwnerModel", owner);
      // this.listOwnerInfo.moreCondition = false;
    },
    queryCompanyOwnerMethod() {
      this.companyListOwner();
    },
    _openAddOwnerRoom: function (_owner) {
      this.$vc.jumpToPage(
          "/flow/addOwnerRoomBindingFlow?ownerId=" +
          _owner.memberId +
          "&tenant=1"
      );
    },
    _openHireParkingSpace: function (_owner) {
      this.$vc.jumpToPage(
          "/flow/hireParkingSpaceFlow?ownerId=" + _owner.memberId
      );
    },
    _openSellParkingSpace: function (_owner) {
      this.$vc.jumpToPage(
          "/flow/sellParkingSpaceFlow?ownerId=" + _owner.memberId
      );
    },
    async _openOwnerDetailModel(tenantInfo) {
      const bindRoom = await this.queryRenterBindRooms(tenantInfo.id);
      let rooms = [];
      bindRoom.data.contracts.forEach((contract) => {
        const {contractId, contractNo} = contract;
        contract.rooms.forEach((room) => {
          rooms.push({
            ...room,
            contractId,
            contractNo,
          });
        });
      });
      const bindRoom2 = await this.queryLesseeBindRooms(tenantInfo.id);

      if (bindRoom2 != null && bindRoom2.length > 0) {
        bindRoom2.forEach((room) => {
          rooms.push({
            "buildingName": room.buildingName,
            "roomName": room.roomName,
          });
        });
      }
      this.tenantDetailModal = {
        visible: true,
        rooms,
        tenantInfo,
      };
    },

    queryLesseeBindRooms(lesseeId) {
      return new Promise((resolve) => {
        this.$fly
            .get(queryLesseeBindRoomsUrl, {
              lesseeId,
            })
            .then((res) => {
              resolve(res.data);
            });
      });
    },

    _openDeleteOwnerRoom: function (_owner) {
      this.$vc.jumpToPage(
          "/flow/deleteOwnerRoomFlow?ownerId=" + _owner.memberId
      );
    },
    _openOwnerRepair: function (_owner) {
      //查看 业主是否有多套房间，如果有多套房间，则提示对话框选择，只有一套房间则直接跳转至交费页面缴费
      this.listOwnerInfo._eventName = "OwnerRepair";
      this.listOwnerInfo._currentOwnerId = _owner.memberId; // 暂存如果有多个房间是回调回来时 ownerId 会丢掉
      var param = {
        params: {
          communityId: this.$vc.getCurrentRegion().communityId,
          ownerId: _owner.ownerId,
        },
      };
      this.$vc.http.get(
          "listOwner",
          "getRooms",
          param,
          (json, res) => {
            var listRoomData = JSON.parse(json);
            var rooms = listRoomData.rooms;
            if (rooms.length == 1) {
              this.$vc.jumpToPage(
                  "/flow/ownerRepairFlow?ownerId=" +
                  _owner.ownerId +
                  "&roomId=" +
                  rooms[0].roomId
              );
            } else if (rooms.length == 0) {
              //this.$vc.message("当前业主未查询到房间信息");
              this.$vc.toast("当前业主未查询到房间信息");
            } else {
              this.$vc.emit(this.$route.path, "searchRoom", "showOwnerRooms", rooms);
            }
          },
          (errInfo, error) => {
            console.log("请求失败处理");
          }
      );
    },
    _openPayPropertyFee: function (_owner) {
      //查看 业主是否有多套房间，如果有多套房间，则提示对话框选择，只有一套房间则直接跳转至交费页面缴费
      this.listOwnerInfo._eventName = "PayPropertyFee";
      this.listOwnerInfo._currentOwnerId = _owner.ownerId; // 暂存如果有多个房间是回调回来时 ownerId 会丢掉
      var param = {
        params: {
          communityId: this.$vc.getCurrentRegion().communityId,
          ownerId: _owner.ownerId,
        },
      };
      this.$vc.http.get(
          "listOwner",
          "getRooms",
          param,
          (json, res) => {
            var listRoomData = JSON.parse(json);
            var rooms = listRoomData.rooms;
            if (rooms.length == 1) {
              this.$vc.jumpToPage(
                  "/flow/listRoomFeeFlow?" +
                  this.$vc.objToGetParam(rooms[0])
              );
            } else if (rooms.length == 0) {
              //this.$vc.message("当前业主未查询到房间信息");
              this.$vc.toast("当前业主未查询到房间信息");
            } else {
              this.$vc.emit(this.$route.path, "searchRoom", "showOwnerRooms", rooms);
            }
          },
          (errInfo, error) => {
            console.log("请求失败处理");
          }
      );
    },
    _openPayParkingSpaceFee: function (_owner) {
      //查看 业主是否有多套停车位，如果有多套停车位，则提示对话框选择，只有一套停车位则直接跳转至交费页面缴费

      this.listOwnerInfo._currentOwnerId = _owner.ownerId; // 暂存如果有多个停车位是回调回来时 ownerId 会丢掉
      var param = {
        params: {
          communityId: this.$vc.getCurrentRegion().communityId,
          ownerId: _owner.ownerId,
        },
      };
      this.$vc.http.get(
          "listOwner",
          "getParkingSpace",
          param,
          (json, res) => {
            var listParkingSpaceData = JSON.parse(json);
            var parkingSpaces = listParkingSpaceData.parkingSpaces;
            if (parkingSpaces.length == 1) {
              //this.$vc.jumpToPage("/flow/parkingSpaceFeeFlow?ownerId=" + _owner.ownerId + "&psId=" + parkingSpaces[0].psId);
              this.$vc.jumpToPage(
                  "/flow/listParkingSpaceFeeFlow?" +
                  this.$vc.objToGetParam(parkingSpaces[0])
              );
            } else if (parkingSpaces.length == 0) {
              //this.$vc.message("当前业主未查询到车位信息");
              this.$vc.toast("当前业主未查询到车位信息");
            } else {
              this.$vc.emit(this.$route.path,
                  "searchParkingSpace",
                  "showOwnerParkingSpaces",
                  parkingSpaces
              );
            }
          },
          (errInfo, error) => {
            console.log("请求失败处理");
          }
      );
    },
    seeWaterBtn(tenant) {
      //带过去的参数
      const params = {
        lesseeId: tenant.id,
        totalRemnant: tenant.waterBalance,
      };
      this.$router.push({
        name: "waterInfo",
        query: params,
      });
    },
    seeElectricityBtn(tenant) {
      //带过去的参数
      const params = {
        lesseeId: tenant.id,
        totalRemnant: tenant.electricityBalance,
      };
      this.$router.push({
        name: "electricityInfo",
        query: params,
      });
    }
  },
};
</script>
<style lang="stylus" scoped>
.tenant-detail-dialog
  .el-dialog__header
    display none

  .preview-tenant
    color #000

    .field
      &-label
        width 200px
        display inline-block
        margin-right 40px
        text-align right
        vertical-align top

      &-content
        display inline-block
        max-width calc(100% - 240px)

.left
  display inline-block
  font-size 24px
  margin-left 80px
  color #000

.list-group
  //margin-top -50px
  line-height 1
  list-style none
  color #000

  li:not(:last-of-type)
    margin-bottom 15px

.btn-active {
  color: #1dafff;
  text-decoration: underline;
  cursor: pointer;
}
</style>
